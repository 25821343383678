import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, Modal, Input, Select, Tabs,notification , Popconfirm,Table, Empty } from 'antd';
import '../styles/ProfilePage.css';
import { eventEmitter } from '../services/EventEmitter';
import { ToastContainer, toast } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; 
import { FaRegEdit } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DownOutlined, UpOutlined ,PlusOutlined } from '@ant-design/icons';
import { startOfWeek, endOfWeek, isSameDay, isBefore,format } from 'date-fns';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const leavePurposes = ['ลาป่วย', 'ลากิจ', 'ลาพักผ่อนประจำปี', 'ลาเพื่อคลอดบุตร', 'ลาเพื่อทำหมัน', 'ลาเพื่อรับราชการทหาร', 'ลาเพื่อการฝึกอบรม', 'ลาเพื่ออุปสมบท'];
const { Option } = Select;
const { TabPane } = Tabs;
const taskOptions = [
  'General Administration',
  'Meeting with Internal Team',
  'Meeting with Client / Supplier',
  'Coordination',
  'Strategic Planning',
  'Creative / Tech Development',
  'Design',
  'Production Supervision',
  'Operation',
  'Miscellaneous',
];
const defaultLeaveStatistics = {
  ลาป่วย: 0,
  ลากิจ: 0,
  ลาพักผ่อนประจำปี: 0,
  ลาเพื่อคลอดบุตร: 0,
  ลาเพื่อทำหมัน: 0,
  ลาเพื่อรับราชการทหาร: 0,
  ลาเพื่อการฝึกอบรม: 0,
  ลาเพื่ออุปสมบท: 0,
};

const leavePurposeText = {
  ลาป่วย: '/ 30 days',
  ลากิจ: '/ 3 days',
  ลาพักผ่อนประจำปี: '/ 14 days',
  ลาเพื่อคลอดบุตร: '/ 98 days',
  ลาเพื่ออุปสมบท: '/ 15 days'
};
const leavePurposeTranslation = {
  ลาป่วย: 'Sick Leave',
  ลากิจ: 'Personal Leave of Absence',
  ลาพักผ่อนประจำปี: 'Annual Leave',
  ลาเพื่อคลอดบุตร: 'Maternity Leave',
  ลาเพื่อทำหมัน: 'Sterilization Leave',
  ลาเพื่อรับราชการทหาร: 'Military Service Leave',
  ลาเพื่อการฝึกอบรม: 'Leave for Training',
  ลาเพื่ออุปสมบท: 'Ordination Leave',
};

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [showLeaveForm, setShowLeaveForm] = useState(false);
  const [leaveDate, setLeaveDate] = useState('');
  const [leaveEndDate, setLeaveEndDate] = useState('');
  const [leaveReason, setLeaveReason] = useState('');
  // const [leaveUnit, setLeaveUnit] = useState('1101 Hospitality Business Unit');
  const [leavePosition, setLeavePosition] = useState('');
  const [leavePurpose, setLeavePurpose] = useState('ลาป่วย');
  const [leaveLocation, setLeaveLocation] = useState('อาคารสรชัย');
  const [leaveStatistics, setLeaveStatistics] = useState(defaultLeaveStatistics);
  const [leaveType, setLeaveType] = useState('เต็มวัน');
  const [halfDayStartTime, setHalfDayStartTime] = useState(null);
  const [halfDayEndTime, setHalfDayEndTime] = useState(null);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const location = useLocation();

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  
  const [timesheets, setTimesheets] = useState([]);
  const [editedRowKeys, setEditedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [activeTab, setActiveTab] = useState('profile');
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [editingTimesheet, setEditingTimesheet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const today = new Date();
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 }); // Start from Monday
  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    format(new Date(startOfCurrentWeek.getTime() + i * 24 * 60 * 60 * 1000), 'MM/dd/yyyy')
  );
  // console.log(daysOfWeek);

  
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const firstName = query.get('firstName');
  const lastName = query.get('lastName');

  const fetchTimesheets = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/timesheet/current-week', { withCredentials: true });
      console.log("response",response.data)
      const flattenedTimesheets = response.data.flatMap((entry) => {
        return entry.date.flatMap((dayRecord) => {
          const formattedDate = format(new Date(dayRecord.date).toISOString().split('T')[0], 'MM/dd/yyyy');  // Ensure correct date format
          
          return dayRecord.records.map((record) => ({
            key: `${entry._id}-${record._id}`,  // Unique key
            date: formattedDate,  // Correctly formatted date
            jobNumber: record.jobNumber || '',
            task: record.task || '',
            workingHours: record.workingHours || 0,
            remark: record.remark || '',
          }));
        });
      });
  
      console.log("flattenedTimesheets", flattenedTimesheets);
      setTimesheets(flattenedTimesheets);
    } catch (error) {
      notification.error({ message: 'Failed to fetch timesheets.' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTimesheets();
  }, []);
  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.date] : []); // Only expand one row at a time
  };
  const handleAddNewRow = (date) => {
    console.log('Adding row for date:', date); // Ensure correct date
    setTimesheets((prevTimesheets) => [
      ...prevTimesheets,
      {
        key: `temp-${Date.now()}`,  // Unique temporary key
        date: date,  // Correct date for the new row
        jobNumber: '',
        task: '',
        remark: '',
        workingHours: 0,
      },
    ]);
  };
  const formatDateToYYYYMMDD = (date) => {
    const inputDate = new Date(date);
    return `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}`;
  };
  
  const handleSaveRow = async (key) => {
    const updatedTimesheets = [...timesheets];
    const recordIndex = updatedTimesheets.findIndex((record) => record.key === key);
    if (recordIndex === -1) return;
  
    const record = updatedTimesheets[recordIndex];
  
    // Format the date to match the server's format (YYYY-MM-DD)
    const formattedDate = formatDateToYYYYMMDD(record.date);  // Assume formatDateToYYYYMMDD is your function
  
    try {
      const response = await axios.put('/api/timesheet/save', {
        date: formattedDate,  // Send the correctly formatted date
        jobNumber: record.jobNumber,
        task: record.task,
        workingHours: record.workingHours,
        remark: record.remark,
        recordKey: record.key.includes('temp') ? null : record.key,  // If it's a new record, send null key
      }, { withCredentials: true });
  
      if (response.status === 200 || response.status === 201) {
        notification.success({ message: 'Timesheet saved successfully.' });
  
        // if (record.key.includes('temp')) {
        //   // The savedTimesheet is the whole timesheet object returned from the backend
        //   const savedTimesheet = response.data.timesheet;
  
        //   // Find the date in the savedTimesheet that matches the saved record's date
        //   const savedDate = savedTimesheet.date.find(d => {
        //     const savedDateFormatted = d.date.split('T')[0];  // Extract only the date part (YYYY-MM-DD)
        //     return savedDateFormatted === formattedDate;  // Compare formatted dates
        //   });
        //   console.log("savedDate",savedDate)
        //   if (savedDate) {
        //     // Find the newly added record in the saved date's records
        //     const newRecord = savedDate.records.find(r => 
        //       r.jobNumber === record.jobNumber && 
        //       r.task === record.task && 
        //       r.workingHours === record.workingHours &&
        //       r.remark === record.remark
        //     );
            
        //     if (newRecord) {
        //       // Update the record key with the new _id from MongoDB
        //       updatedTimesheets[recordIndex].key = `${savedTimesheet._id}-${newRecord._id}`;
        //     }
        //   } else {
        //     console.error("Saved date not found in the response.");
        //   }
        // }
  
        fetchTimesheets();
        setTimesheets(updatedTimesheets);
        setEditedRowKeys(editedRowKeys.filter(editedKey => editedKey !== key));
          // Clear edited row keys
      }
    } catch (error) {
      notification.error({ message: 'Failed to save the timesheet.' });
      console.error('Error saving the timesheet:', error);
    }
  };
  const handleDeleteRecord = async (recordKey) => {
    const record = timesheets.find((item) => item.key === recordKey);
    
    if (!record) return; // Safety check
  
    if (recordKey.includes('temp')) {
      // Handle deleting a newly created unsaved row
      setTimesheets((prevTimesheets) => prevTimesheets.filter((item) => item.key !== recordKey));
      notification.success({ message: 'Unsaved row deleted successfully.' });
    } else {
      // Handle deleting an existing row from the database
      try {
        // Send delete request to your server
        await axios.delete(`/api/timesheet/${recordKey}`, { withCredentials: true });
  
        // Update the local state to remove the deleted record
        setTimesheets((prevTimesheets) => prevTimesheets.filter((item) => item.key !== recordKey));
  
        notification.success({ message: 'Record deleted successfully.' });
      } catch (error) {
        notification.error({ message: 'Failed to delete record.' });
      }
    }
  };
  const handleFieldChange = (key, fieldName, value) => {
    const updatedTimesheets = [...timesheets];
    const recordIndex = updatedTimesheets.findIndex((record) => record.key === key);
    updatedTimesheets[recordIndex][fieldName] = value;

    setTimesheets(updatedTimesheets);
    console.log("editedRowKeys",editedRowKeys)
    if (!editedRowKeys.includes(key)) {
      setEditedRowKeys([...editedRowKeys, key]);
    }
  };

  const EditableCell = ({ editable, dataIndex, record }) => {
    const [localValue, setLocalValue] = useState(record[dataIndex]);
  
    const handleChange = (e) => {
      const value = e.target ? e.target.value : e;
      setLocalValue(value); // Keep the value locally first
    };
  
    const handleBlur = () => {
      // Only update the state and handle changes when the input loses focus
      handleFieldChange(record.key, dataIndex, localValue);
    };
  
    const handleFocus = () => {
      // Mark the row as edited when the user first focuses on the cell
      if (!editedRowKeys.includes(record.key)) {
        setEditedRowKeys([...editedRowKeys, record.key]);
      }
    };
  
    if (editable) {
      if (dataIndex === 'task') {
        return (
          <Select
            defaultValue={localValue}
            onChange={(value) => {
              setLocalValue(value);
              handleFieldChange(record.key, dataIndex, value); // Direct update for Select
            }}
            onFocus={handleFocus}
            style={{ width: '100%' }}
          >
            {taskOptions.map((task) => (
              <Option key={task} value={task}>
                {task}
              </Option>
            ))}
          </Select>
        );
      }
  
      if (dataIndex === 'workingHours') {
        return (
          <Input
            type="number"
            value={localValue}
            onChange={handleChange} // Use local value for smooth input
            onBlur={handleBlur} // Update only when the user leaves the field
            onFocus={handleFocus}
            min={0}
          />
        );
      }
  
      return (
        <Input
          value={localValue}
          onChange={handleChange} // Use local value for smooth input
          onBlur={handleBlur} // Update only when the user leaves the field
          onFocus={handleFocus}
        />
      );
    }
  
    return <div>{localValue}</div>;
  };
  
  // const EditableCell = ({ editable, children, dataIndex, record }) => {
  //   const handleChange = (value) => {
  //     const updatedTimesheets = [...timesheets];
  //     const recordIndex = updatedTimesheets.findIndex((r) => r.key === record.key);
  //     updatedTimesheets[recordIndex][dataIndex] = value;
  
  //     setTimesheets(updatedTimesheets);
  
  //     // Add the row to editedRowKeys if it's not already there
  //     if (!editedRowKeys.includes(record.key)) {
  //       setEditedRowKeys([...editedRowKeys, record.key]);
  //     }
  //   };
  
  //   if (editable) {
  //     if (dataIndex === 'task') {
  //       return (
  //         <>
  //           <Select
  //             defaultValue={record[dataIndex]}
  //             onChange={handleChange}
  //             style={{ width: '100%' }}
  //           >
  //             {taskOptions.map((task) => (
  //               <Option key={task} value={task}>
  //                 {task}
  //               </Option>
  //             ))}
  //           </Select>
  //           {editedRowKeys.includes(record.key) && (
  //             <Button type="primary" onClick={() => handleConfirmRow(record.key)}>
  //               Confirm
  //             </Button>
  //           )}
  //         </>
  //       );
  //     }
  
  //     if (dataIndex === 'workingHours') {
  //       return (
  //         <>
  //           <Input
  //             type="number"
  //             defaultValue={record[dataIndex]}
  //             onBlur={(e) => handleChange(Number(e.target.value))}
  //             min={0}
  //           />
  //           {editedRowKeys.includes(record.key) && (
  //             <Button type="primary" onClick={() => handleConfirmRow(record.key)}>
  //               Confirm
  //             </Button>
  //           )}
  //         </>
  //       );
  //     }
  
  //     return (
  //       <>
  //         <Input
  //           defaultValue={record[dataIndex]}
  //           onBlur={(e) => handleChange(e.target.value)}
  //         />
  //         {editedRowKeys.includes(record.key) && (
  //           <Button type="primary" onClick={() => handleConfirmRow(record.key)}>
  //             Confirm
  //           </Button>
  //         )}
  //       </>
  //     );
  //   }
  
  //   return <div>{children}</div>;
  // };

  const columns = [
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      editable: true,
      render: (text, record) => (
        <EditableCell editable={true} dataIndex="jobNumber" record={record} />
      ),
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      editable: true,
      render: (text, record) => (
        <EditableCell editable={true} dataIndex="task" record={record} />
      ),
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      key: 'workingHours',
      editable: true,
      render: (text, record) => (
        <EditableCell editable={true} dataIndex="workingHours" record={record} />
      ),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      editable: true,
      render: (text, record) => (
        <EditableCell editable={true} dataIndex="remark" record={record} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          {editedRowKeys.includes(record.key) && (
            <Button type="primary" onClick={() => handleSaveRow(record.key)}>
              Confirm
            </Button>
          )}
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDeleteRecord(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const expandedRowRender = (date) => {
    const filteredRows = timesheets.filter((row) => row.date === date);
    
    if (filteredRows.length === 0) {
      return (
        <div className="empty-expanded-row">
          <p>No records found for this date.</p>
        </div>
      );
    }
  
    return (
      <Table
        columns={columns}
        dataSource={filteredRows}
        pagination={false}
        rowKey="key"
        style={{ width: '100%' }}  // Ensure full-width
      />
    );
  };

  const tableColumns = columns.map((col) => ({
    ...col,
    onCell: (record, rowIndex) => ({
      editable: col.editable,
      dataIndex: col.dataIndex,
      record,
      rowIndex,
    }),
  }));

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Include firstName and lastName in the API request if they are provided
        const response = await axios.get(`${API_BASE_URL}/api/users/profile${firstName && lastName ? `?firstName=${firstName}&lastName=${lastName}` : ''}`, { withCredentials: true });
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    

    fetchProfile();
    fetchLeaveStatistics();
    fetchLeaveRequests();
  }, [firstName, lastName]);

  const handleTabChange = (key) => {
    setActiveTab(key); // Set active tab
  };

  
  const disableFutureDates = (date) => {
    return date > new Date();
  };

  const fetchLeaveStatistics = async () => {
    try {
      const year = new Date().getFullYear();
      const response = await axios.get(`${API_BASE_URL}/api/users/statistics?year=${year}${firstName ? `&firstName=${firstName}` : ''}${lastName ? `&lastName=${lastName}` : ''}`, { withCredentials: true });
      const data = response.data;
      const updatedStatistics = { ...defaultLeaveStatistics, ...data };
      setLeaveStatistics(updatedStatistics);
    } catch (error) {
      console.error('Error fetching leave statistics:', error);
    }
  };

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/leave-letters-status`, { withCredentials: true });
      setLeaveRequests(response.data);
    } catch (error) {
      console.error('Error fetching leave requests:', error);
    }
  };

  const handleLeaveSubmit = async (e) => {
    e.preventDefault();

    if (!acceptTerms) {
      toast.error('Please accept the terms and conditions.');
      return;
    }

    // Calculate the date difference to determine if a file needs to be attached
    const startDate = new Date(leaveDate);
    const endDate = new Date(leaveEndDate);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    try {
      const formData = new FormData();
      
      const fileInput = document.querySelector('#leaveFile');
      const file = fileInput.files[0];
      if (file) {
        formData.append('file', file);
        formData.append('originalFileName', encodeURIComponent(file.name)); // Encode the file name
      
      }
      formData.append('fromDate', leaveDate);
      formData.append('toDate', leaveEndDate);
      formData.append('reason', leaveReason);
      // formData.append('unit', leaveUnit);
      formData.append('position', leavePosition);
      formData.append('purpose', leavePurpose);
      formData.append('location', leaveLocation);
      formData.append('leaveType', leaveType);
      if (halfDayStartTime) {
        formData.append('halfDayStartTime', halfDayStartTime.toISOString());
      }

      if (halfDayEndTime) {
        formData.append('halfDayEndTime', halfDayEndTime.toISOString());
      }

      await axios.post(`${API_BASE_URL}/api/users/leave-letters`, formData, { withCredentials: true });
      setShowLeaveForm(false);
      toast.success('Leave request submitted successfully!');
      fetchLeaveRequests();
    } catch (error) {
      console.error('Error submitting leave request:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Failed to submit leave request.');
      }
    }
  };

  const handleCancelLeave = async (leaveId) => {
    try {
      await axios.post(`${API_BASE_URL}/api/users/cancel-leave/${leaveId}`, {}, { withCredentials: true });
      toast.success('Leave request canceled successfully!');
      fetchLeaveRequests();
    } catch (error) {
      console.error('Error canceling leave request:', error);
      toast.error('Failed to cancel leave request.');
    }
  };

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/reset-password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      }, { withCredentials: true });

      if (response.status === 200) {
        toast.success("Password reset successfully!");
        setShowResetPasswordForm(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Failed to reset password. Please try again.');
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('profileImage', file);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/update-profile-image`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.profileImage) {
        setUserInfo({
          ...userInfo,
          profileImage: response.data.profileImage,
        });
        eventEmitter.emit('profileUpdated');
      }
      toast.success("Update profile image successfully!");
    } catch (error) {
      console.error('Error updating profile image:', error);
      toast.error('Failed to update profile image.');
    }
  };

  const handleLeaveStatisticClick = (purpose) => {
    setLeavePurpose(purpose);
    setShowLeaveForm(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'leave-form-overlay') {
      setShowLeaveForm(false);
    }
  };
 
  const startDate = new Date(leaveDate);
  const endDate = new Date(leaveEndDate);
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

  return (
    <div className="profile-page">
      <Tabs defaultActiveKey="profile" onChange={handleTabChange}>
        <TabPane tab="Profile" key="profile">
          <div className="user-info-and-action">
            <div className="user-info">
              <div className="profile-image-wrapper" onClick={() => document.getElementById('profileImageInput').click()}>
                <img
                  src={`${API_BASE_URL}/${userInfo.profileImage}`}
                  alt="Profile"
                  className="profile-image"
                />
                <FaRegEdit className="edit-icon" />
              </div>
              <input
                type="file"
                id="profileImageInput"
                onChange={handleProfileImageChange}
                style={{ display: 'none' }}
                accept="image/*"
              />
              <h1>{`${userInfo.firstName} ${userInfo.lastName}`}</h1>
              <p>{userInfo.nickname}</p>
              <p>{`Business: ${userInfo.business}`}</p>
              <p>{`Employee ID: ${userInfo.player_code}`}</p>
              <p>{`Position: ${userInfo.position}`}</p>
            </div>
            {userInfo.isCurrentUser && (
              <div className="leave-status-section">
                <button className="leave-request-button" onClick={() => setShowLeaveForm(true)}>ลางาน</button>
                <button className="reset-password-button" onClick={() => setShowResetPasswordForm(true)}>Reset Password</button>
                <h2>Leave Status</h2>
                <div className="leave-requests-list">
                  {leaveRequests.map((leave, index) => (
                    <div key={index} className="leave-request-card">
                      <h3>{`${leave.fromDate.split('T')[0]} to ${leave.toDate.split('T')[0]}`}</h3>
                      <p>{`Purpose: ${leave.purpose}`}</p>
                      <p className={`status ${leave.status.replace(/\s+/g, '-').toLowerCase()}`}>{leave.status}</p>
                      {!leave.emailSent && (
                        <button onClick={() => handleCancelLeave(leave._id)}>Cancel</button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex-container">
            <div className="projects-participant-section">
              <h2>Projects Participation</h2>
              <div className="projects-list">
                {userInfo.tasks.map((task, index) => (
                  <div key={index} className="project-card">
                    <h3>{`Project Name:${task.projectName}`}</h3>
                    <div className="task-details">
                      <span className="task-description">{`Task: ${task.taskDescription}`}</span>
                      <span className="task-position">{`Position: ${task.position}`}</span>
                      <p className={`status ${task.status.replace(/\s+/g, '-').toLowerCase()}`}> {task.status}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="leave-statistics-section">
              <h2>Leave Statistics for {new Date().getFullYear()}</h2>
              <Row gutter={[16, 16]}>
                {Object.entries(leaveStatistics).map(([purpose, days]) => (
                  <Col span={8} key={purpose}>
                    <Card
                      title={leavePurposeTranslation[purpose] || purpose}
                      bordered={false}
                      onClick={() => handleLeaveStatisticClick(purpose)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`${days} ${leavePurposeText[purpose] || 'days'}`}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          {showLeaveForm && (
            <div className="leave-form-overlay" onClick={handleOverlayClick}>
              <div className="leave-form-container">
                <form onSubmit={handleLeaveSubmit} className="leave-form">
                  <h2>Leave Request Form</h2>
                  {/* <div className="form-field">
                    <label htmlFor="leaveUnit">หน่วยงาน:</label>
                    <select id="leaveUnit" value={leaveUnit} onChange={(e) => setLeaveUnit(e.target.value)} required>
                      <option value="1101 Hospitality Business Unit">1101 Hospitality Business Unit</option>
                      <option value="1102 Entertainment Business Unit">1102 Entertainment Business Unit</option>
                      <option value="1103 Events & Exhibitions Business Unit">1103 Events & Exhibitions Business Unit</option>
                      <option value="2204 Strategy & Business Solutions Unit">2204 Strategy & Business Solutions Unit</option>
                      <option value="2205 Corporate Culture Development">2205 Corporate Culture Development</option>
                      <option value="2206 Finance & Accounting">2206 Finance & Accounting</option>
                      <option value="MD">MD</option>
                    </select>
                  </div> */}
                  <div className="form-field">
                    <label htmlFor="leavePosition">ตำแหน่ง:</label>
                    <input type="text" id="leavePosition" value={leavePosition} onChange={(e) => setLeavePosition(e.target.value)} required />
                  </div>
                  <div className="form-field">
                    <label htmlFor="leavePurpose">วัตถุประสงค์การลา:</label>
                    <select id="leavePurpose" value={leavePurpose} onChange={(e) => setLeavePurpose(e.target.value)} required>
                      <option value="ลาป่วย">ลาป่วย (30 วัน/ปี) *หากลา3วันขึ้นไป กรุณาแนบใบรับรองแพทย์</option>
                      <option value="ลากิจ">ลากิจ (3 วัน/ปี) *เพื่อกิจธุระอันจำเป็น</option>
                      <option value="ลาพักผ่อนประจำปี">ลาพักผ่อนประจำปี (14 วัน/ปี)</option>
                      <option value="ลาเพื่อคลอดบุตร">ลาเพื่อคลอดบุตร (98 วัน)</option>
                      <option value="ลาเพื่อทำหมัน">ลาเพื่อทำหมัน</option>
                      <option value="ลาเพื่อรับราชการทหาร">ลาเพื่อรับราชการทหาร</option>
                      <option value="ลาเพื่อการฝึกอบรม">ลาเพื่อการฝึกอบรม หรือพัฒนาความรู้ความสามารถ</option>
                      <option value="ลาเพื่ออุปสมบท">ลาเพื่ออุปสมบท</option>
                      <option value="ลาหยุดในกรณีอื่นๆ">ลาหยุดในกรณีอื่นๆ</option>
                      
                    </select>
                  </div>
                  <div className="form-field">
                    <label htmlFor="leaveLocation">Location:</label>
                    <select id="leaveLocation" value={leaveLocation} onChange={(e) => setLeaveLocation(e.target.value)} required>
                      <option value="อาคารสรชัย">Head Office at 695 MQDC Brite</option>
                      <option value="The Forestias (บางนา)">The Forestias (บางนา)</option>
                    </select>
                  </div>
                  <div className="form-field">
                    <label htmlFor="leaveType">ประเภทการลา:</label>
                    <select id="leaveType" value={leaveType} onChange={(e) => setLeaveType(e.target.value)} required>
                      <option value="เต็มวัน">เต็มวัน</option>
                      <option value="ครึ่งวัน">ครึ่งวัน</option>
                    </select>
                  </div>
                  {leaveType === 'ครึ่งวัน' && (
                    <>
                      <div className="form-field">
                        <label htmlFor="halfDayStartTime">เริ่มครึ่งวัน:</label>
                        <DatePicker
                          id="halfDayStartTime"
                          selected={halfDayStartTime}
                          onChange={(date) => setHalfDayStartTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="เวลา"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          required
                        />
                      </div>
                      <div className="form-field">
                        <label htmlFor="halfDayEndTime">สิ้นสุดครึ่งวัน:</label>
                        <DatePicker
                          id="halfDayEndTime"
                          selected={halfDayEndTime}
                          onChange={(date) => setHalfDayEndTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="เวลา"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="form-field">
                    <label htmlFor="leaveStartDate">Start Date:</label>
                    <input type="date" id="leaveStartDate" value={leaveDate} onChange={(e) => setLeaveDate(e.target.value)} required />
                  </div>
                  <div className="form-field">
                    <label htmlFor="leaveEndDate">End Date:</label>
                    <input type="date" id="leaveEndDate" value={leaveEndDate} onChange={(e) => setLeaveEndDate(e.target.value)} required />
                  </div>
                  {leavePurpose === 'ลาหยุดในกรณีอื่นๆ' && (
                    <div className="form-field">
                      <label htmlFor="leaveReason">Reason:</label>
                      <textarea id="leaveReason" value={leaveReason} onChange={(e) => setLeaveReason(e.target.value)} required />
                    </div>
                  )}
                  
                  <div>
                    <label htmlFor="leaveFile">Attach File:</label>
                    <input type="file" id="leaveFile" />
                  </div>
                  
                  <div className="form-actions">
                    <div className="terms-container">
                      <input
                        type="checkbox"
                        id="acceptTerms"
                        checked={acceptTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                        required
                      />
                      <label htmlFor="acceptTerms">
                        <span className="terms-text">
                          *เงื่อนไขการลา: สมาชิกที่ต้องการจะลาหยุดจะต้องกรอกข้อมูลการลาในระบบ เพื่อให้ผู้บังคับบัชชารับทราบและยืนยันอนุมัติการลาทุกครั้ง
                        </span>
                        <p></p>
                        <span className="terms-text">
                          *สามารถยกเลิกภายใน 60 นาทีหลังจาก Submit(หากเกิน60นาทีจะไม่สามารถยกเลิกได้)
                        </span>
                      </label>
                    </div>
                    <div className="action-buttons">
                      <button type="submit">Submit</button>
                      <button type="button" onClick={() => setShowLeaveForm(false)}>Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            
          )}
          {showResetPasswordForm && (
            <div className="reset-password-form-overlay" onClick={handleOverlayClick}>
              <div className="reset-password-form-container">
                <form onSubmit={handleResetPassword} className="reset-password-form">
                  <h2>Reset Password</h2>
                  <div className="form-field">
                    <label htmlFor="currentPassword">Current Password:</label>
                    <input type="password" id="currentPassword" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
                  </div>
                  <div className="form-field">
                    <label htmlFor="newPassword">New Password:</label>
                    <input type="password" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                  </div>
                  <div className="form-field">
                    <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                    <input type="password" id="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
                  </div>
                  <div className="form-actions">
                    <button type="submit">Confirm</button>
                    <button type="button" onClick={() => setShowResetPasswordForm(false)}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </TabPane>
        {userInfo.isCurrentUser && (  
          <TabPane tab="Timesheet" key="timesheet">
              <Table
                columns={[
                  {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: (date) => <div>{date}</div>,
                  },
                  {
                    title: 'Actions',
                    dataIndex: 'actions',
                    key: 'actions',
                    render: (_, record) => (
                      expandedRowKeys.includes(record.date) && (
                        <PlusOutlined
                          onClick={() => handleAddNewRow(record.date)}
                          style={{ cursor: 'pointer', fontSize: '16px', color: '#1890ff' }}
                        />
                      )
                    ),
                  },
                ]}
                dataSource={daysOfWeek.map((day) => ({ date: day }))}
                expandable={{
                  expandedRowRender: (record) => expandedRowRender(record.date),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <UpOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <DownOutlined onClick={(e) => onExpand(record, e)} />
                    ),
                  expandedRowKeys: expandedRowKeys, // Updated expandable keys
                  onExpand: handleExpand, // Handle expansion/collapse
                }}
                rowKey="date"
                pagination={false}
                scroll={{ x: '100%' }}
                tableLayout="fixed"
                style={{ width: '100%' }}
              />
          </TabPane>
          )}  
      </Tabs>
      <ToastContainer />
    </div>
  );
};

export default ProfilePage;
